<template>
    <div>
        <div class="text-lg uppercase mb-4">
            Stammdaten
        </div>

        <general-data-update-form v-model="internValue" :errors="errors"/>

        <div class="flex justify-content-between">
            <div class="block">
                <Button
                    label="Zurück"
                    @click="$router.back()"
                    class="p-button-secondary p-button-sm cursor-pointer"
                />
            </div>
            <div class="block">
                <Button
                    v-if="!$v.$invalid"
                    @click="nextPage()"
                    label="Weiter"
                    class="p-button-sm cursor-pointer"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import {required, minLength} from 'vuelidate/lib/validators'
    import Button from "primevue/button/Button";
    import Errors from "@/components/form/Errors";
    import InputText from "primevue/inputtext/InputText";
    import Textarea from 'primevue/textarea';
    import Value from "@/mixin/Value";
    import GeneralDataUpdateForm from "@/components/data/offer/GeneralDataUpdateForm";

    export default {
        mixins: [Value],
        validations: {
            internValue: {
                name: {
                    required,
                    minLength: minLength(1)
                },
                description: {
                    required,
                    minLength: minLength(1)
                }
            },
        },
        data() {
            return {
                internValue: {},
                internErrors: {},
            }
        },
        mounted() {
            if(!this.$route.query) {
                this.$router.push('/offers');
                this.$toast.add({
                    summary: 'Warnung',
                    severity: 'warn',
                    detail: 'Kein Diensleister zum Erstellen der Dienstleistung ausgewählt',
                    life: 3000
                });
            } else {
                this.$getFromApi('companies', this.$route.query.companyId, (entity) => {
                    this.company = entity;
                    this.internValue['provider'] = entity['@id'];
                });
            }

            this.internValue = Object.assign({}, this.value);
            this.internErrors = Object.assign({}, this.errors);
        },
        components: {
            GeneralDataUpdateForm,
            Textarea,
            Errors,
            Button,
            InputText
        },
        computed: {
            errors: function() {
                return {
                    name: this.$v.internValue.name.$invalid ? ['Dies ist ein Pflichtfeld'] : [],
                    description: this.$v.internValue.description.$invalid ? ['Dies ist ein Pflichtfeld'] : []
                }
            }
        },
        methods: {
            nextPage() {
                this.$emit('input', this.internValue);
                this.$emit('nextPage', {});
            }
        }
    }
</script>
